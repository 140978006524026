$sidebar-bg-color: #e4e4e4 !default;
$sidebar-color: #7e7e7e !default;
$sidebar-width: 240px !default;
$sidebar-collapsed-width: 40px !default;
$highlight-color: #000000 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 0px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1385px !default;

::-webkit-scrollbar {
    width: 5px;
    scroll-padding-right: 10px;
  }

::-webkit-scrollbar-thumb {
    background: rgb(200, 200, 200);
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(150, 150, 150);
}

@import '~react-pro-sidebar/dist/scss/styles.scss';